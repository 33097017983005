html,
body {
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  padding: 0;
  font-variant-ligatures: none;
  font-family: Noto;
  font-weight: 400;
  line-height: 150%;
}

body.modal-open {
  overflow: hidden;
}

button {
  font-family: Noto;
  background: none;
  border: none;
}

p,
h1,
h2,
h3,
ul {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Noto;
  src: url(assets/fonts/NotoSans-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto;
  src: url(assets/fonts/NotoSans-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto;
  src: url(assets/fonts/NotoSans-SemiBold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noto;
  src: url(assets/fonts/NotoSans-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Creepster;
  src: url(assets/fonts/Creepster.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mystery Quest;
  src: url(assets/fonts/Mystery-Quest.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

textarea:focus, input:focus, button:focus {
  outline: none;
}
